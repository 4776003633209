<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :bannerImg="bannerImg" :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="forms" src="@/assets/img/domainQuiz/forms.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle
          title="能力展示"
          desc="支持pdf、word、txt文档格式，单个文档大小限制15M；想通过接口 对接到自己的系统？联系我们。"
        />
        <div class="operationWidth similar-main-cont">
          <div class="upload-wrap" :class="{ frosted_glass: isActive }">
            <ImgUpload
              class="sc-img-upload"
              @success="getResultImg"
              @select="selectPicture"
              @change="handleChange"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :multiple="multiple"
              :action="action"
              accept=".doc,.docx,.txt,.pdf"
              uploadText="上传文档"
            >
              <!-- <div class="upload-title" slot="title"><img :src="titleIcon" alt="">图片</div> -->
            </ImgUpload>

            <!-- <div class="logoUploadMain">
              <div class="logoExample" :class="{ 'prohibitClicking': prohibitClicking == true }">
                  <div 
                    class="logoExampleMain" 
                    v-for="(item, index) in exampleDiagram" 
                    :key="index"
                    @click="selectPicture(index, item.documentId,item.documentUrl)" 
                    :class="{ 'imgActive': imgCurrentActive === index ,'prohibit': prohibit == true}"
                    >
                    <img :src="item.img" />
                  </div>
                </div>
                <el-upload 
                  :action="action" 
                  class="logoUpload" 
                  :class="{ 'prohibit': prohibit == true }" 
                  auto-upload:false 
                  list-type="picture-card" 
                  :multiple="multiple"
                  :file-list="fileList" 
                  :on-change="handleChange" 
                  :show-file-list="false" 
                  :data="uploadData"
                  :on-success="getResultImg" 
                  accept=".doc,.docx,.txt,.pdf" 
                  :headers="headers" 
                  :before-upload="beforeUpload">
                    <i class="el-icon-plus"></i>
                </el-upload>
                
            </div> -->
          </div>
          <div class="chat-wrap">
            <div class="pdfName">
              {{ name }}
            </div>
            <div class="chatting" id="chatting" ref="scrollWrapper">
              <!-- 问题 -->
              <div style="position: relative">
                <div v-for="i in queslist">
                  <div class="problemMain">
                    <div class="problemDomain">
                      {{ i.text }}
                    </div>
                    <div class="problemDomainText">问题</div>
                  </div>
                  <!-- 答案 -->
                  <div class="answerMain">
                    <div class="answerDomainText">AI</div>
                    <div class="answerDomain"></div>
                  </div>
                </div>
                <div class="loadingDomain" v-if="this.loading">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div class="sendContent">
              <div class="selectLabelDomain">
                <span
                  v-for="(item, index) in menuDemoList"
                  :key="index"
                  @click="selectLabelDomain(index, item.value)"
                  :class="{ prohibit: prohibit == true }"
                >
                  {{ item.value }}</span
                >
              </div>

              <el-form :model="formUrl">
                <el-form-item label="">
                  <el-input
                    placeholder="输入要处理的问题："
                    v-model="formUrl.text"
                    type="textarea"
                    resize="none"
                    class="domainInput"
                  ></el-input>
                </el-form-item>
                <el-button
                  type="primary"
                  class="subBtn_domain"
                  @click="submitForm()"
                  :disabled="this.sendisabled"
                  :class="{ prohibit: prohibit == true }"
                  >发送</el-button
                >
              </el-form>
            </div>
          </div>
          <div class="msg-tip" style="display: none" id="msgTip">
            文件解析中...
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main"
          theme="light"
          :list="diffList"
          :img="diffImg"
        ></ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="applicationImg"
      >
        <div slot="img" class="app-img">
          <img :src="applicationImg" alt="" />
          <div class="app-chat-gif">
            <img src="@/assets/img/domainQuiz/2023.gif" alt="" />
          </div>
        </div>
      </ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

import banner1 from '@/assets/img/chatgpts/bg.png';
import hexin from '@/assets/img/domainQuiz/core.png';
import applicationImg from '@/assets/img/domainQuiz/application.png';
import differentImg from '@/assets/img/domainQuiz/different.png';
import yingyong from '@/assets/img/gongneng/zhishi/changjing.jpg';
import { apiUri } from '../../api/txt';

import { documentQa, getDocument } from '@/api/aidata.js';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '../../components/foot/footer.vue';

// 公用组件
import '../../assets/css/normalize.css';
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
import '../../api/canvas/jquery.min.js';
export default {
  name: 'domainQuiz',
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Viewheader,
    ImgUpload,
    Footering,
  },
  data() {
    return {
      bannerImg: banner1,
      coreImg: hexin,
      applicationImg: applicationImg,
      differentImg: differentImg,
      bigTitle_1: 'Feitian领域知识问答',
      smallTitle_1: '根据用户上传的非结构化文档或知识库，实现领域知识问答',

      // 核心功能
      spanList: [
        {
          title: '自定义知识库',
          desc: '支持用户自建自身业务领域下的“大规模”知识库，支撑word、txt、pdf文档的文本提取，并通过大模型实现个人及企业级的知识管理。',
        },
        {
          title: '领域知识开放问答',
          desc: '支持用户进行“开放式”问答（也可预设问题），答案严格遵从知识库本身。',
        },
      ],

      //   差异优势
      diffImg: differentImg,
      diffList: [
        {
          title: '支持标准接口接入',
          desc: '支持企业级用户接口接入，实现批量上传文档、文档的增删改。',
        },
        {
          title: '多用户差异化回复',
          desc: '接口支持不同提问者针对不同范围知识库进行提问，使不同用户身份的用户得到不同的答案。同时，接口集成了结构化FQA的问答、支持配置系统内结构化接口查询（如查订单、查物流）等；',
        },
      ],

      //   应用场景
      appImg: yingyong,
      appliList: [
        {
          title: '电商商详页常驻助理',
          desc: '基于现有商品商详页的信息、数据库中结构化业务数据、历史im信息，批量化生成每个商品的知识文档，并结合预设问题项。实现用户进页面即可了解到商品的核心卖点、评论观点等信息，并支持用户开放提问关心问题。优化用户下单决策流程。',
        },
        {
          title: '卖家自动化构建智能IM',
          desc: '无需卖家逐条录入知识，根据商品商详页的信息、数据库中结构化业务数据、历史im信息自动构建知识库，并对进店买家问题进行回复。',
        },
      ],
      menuDemoList: [
        {
          key: '1',
          value: '根据文档内容，联想几条问题',
        },
        {
          key: '2',
          value: '文档中有哪些核心观点？',
        },
      ],
      // 下角标
      activeIndex: null,
      formUrl: {
        text: '',
      },
      sendisabled: false,
      action: `${apiUri}/text/plat/uploadDocument`,
      multiple: true,
      fileList: [],
      uploadData: {
        // 用户id
        isOverwrite: 1,
      },
      // 示例图列表
      // exampleDiagram: [
      //   {
      //       img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/PDF.png',
      //       type:'pdf',
      //       id:'425794767',
      //       name:'file/pdf-跨境电商独立站低成本引流的五大方法.pdf'
      //   }, {
      //       img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/WORD.png',
      //       type:'word',
      //       id:'81075546',
      //       name:'file/word-跨境电商独立站低成本引流的五大方法.docx'
      //   }, {
      //       img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/TXT.png',
      //       type:'txt',
      //       id:'1829414612',
      //       name:'file/txt-跨境电商独立站低成本引流的五大方法.txt'
      //   }],
      // 初始化选中状态
      imgCurrentActive: 0,
      prohibitClicking: false,

      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },
      id: '',
      name: '',
      formUrl: {
        text: '',
      },
      txtList: [],
      queslist: [],
      answlist: [],
      i: '',
      prohibit: false,
      loading: false,
      isActive: false,
      exampleDiagram: [],
      dataLength: '',
    };
  },
  watch: {
    exampleDiagram: {
      deep: true,
      handler(val) {
        console.log('watch:exampleDiagram', val);
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    // 在组件挂载后将滚动条设置为最底部
    this.scrollToEnd();
    this.getDocument();
    window.addEventListener('keydown', this.handleKeydown);
  },
  updated() {
    // 在组件更新后将滚动条设置为最底部
    this.scrollToEnd();
  },

  methods: {
    // 查询文档
    getDocument() {
      if (localStorage.getItem('tokenKey')) {
        // 有token
        this.isSample = 1;
      } else {
        // 无token
        this.isSample = 0;
      }
      let params = {
        isSample: this.isSample,
        // isSample:1
      };
      getDocument(params).then(({ data }) => {
        this.exampleDiagram = data.data;
        // this.$set(this, 'exampleDiagram', data.data)
        this.dataLength = this.exampleDiagram.length;
        if (this.dataLength < 1) {
          this.prohibitClicking = true;
        }
        for (var i = 0; i < this.dataLength; i++) {
          if (this.exampleDiagram[i].documentUrl.indexOf('pdf') != -1) {
            // this.$set(this.exampleDiagram[i], 'img', 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/PDF.png')
            this.exampleDiagram[i].img =
              'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/PDF.png';
          } else if (
            this.exampleDiagram[i].documentUrl.indexOf('doc') != -1 ||
            this.exampleDiagram[i].documentUrl.indexOf('docx') != -1
          ) {
            // this.$set(this.exampleDiagram[i], 'img', 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/WORD.png')
            this.exampleDiagram[i].img =
              'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/WORD.png';
          } else {
            // this.$set(this.exampleDiagram[i], 'img', 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/TXT.png')
            this.exampleDiagram[i].img =
              'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/TXT.png';
          }
        }

        console.log('exampleDiagram', this.exampleDiagram);

        // 高亮操作
        this.selectPicture(this.imgCurrentActive, {
          documentId: this.exampleDiagram[0].documentId,
          documentUrl: this.exampleDiagram[0].documentUrl,
        });
      });
    },
    scrollToEnd() {
      // 获取滚动条所在的元素
      // // 获取最后一个标签
      // this.$nextTick(() => {
      const scrollWrapper = this.$refs.scrollWrapper;
      // console.log(scrollWrapper)
      if (scrollWrapper != undefined) {
        // 将滚动条滚动到最底部
        scrollWrapper.scrollTop = scrollWrapper.scrollHeight;
      }

      // })
    },

    // 点击选中高亮
    selectPicture(index, { documentId: id, documentUrl: name }) {
      this.name = name;
      this.id = id;
      this.imgCurrentActive = index;
      // // 获取最后一个标签
      this.$nextTick(() => {
        var pTags = document.querySelectorAll('.answerDomain');
        if (pTags.length != 0) {
          // console.log(pTags.length)
          var lastPTag = pTags[pTags.length - 1];
          // 追加内容
          lastPTag.innerHTML = '';
        }
      });
      this.queslist = [];
      this.txtList = '';
      if (this.dataLength > 1) {
        this.formUrl.text = '文档的核心内容是什么，请逐一罗列。';
        this.submitForm();
        // if(index == 0){
        //   this.formUrl.text = '在吗1？'
        //   this.submitForm();
        // }else if(index == 1){
        //   this.formUrl.text = '在吗2？'
        //   this.submitForm();
        // }else if(index == 2){
        //   this.formUrl.text = '在吗3？'
        //   this.submitForm();
        // }
      }
    },

    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {},
    // 上传文件发生改变时
    handleChange(file, fileList) {
      // return;
      // console.log(file)
      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';
    },
    beforeUpload(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (['doc', 'docx', 'txt', 'pdf'].indexOf(FileExt.toLowerCase()) === -1) {
        this.$message({
          type: 'warning',
          message: '请上传后缀名为.doc,.docx,.txt,.pdf的附件！',
          offset: window.screen.height / 2,
        });
        this.isActive = false;
        document.getElementById('msgTip').style.display = 'none';
        return false;
      }
    },
    // 上传成功
    getResultImg(data, file, fileList) {
      console.log(file.name);
      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';
      // if(file.name.indexOf("pdf") == -1||file.name.indexOf("doc") == -1||file.name.indexOf("docx") == -1||file.name.indexOf("txt") == -1){
      //     return  this.$message({
      //         message: "仅支持pdf、doc、docx、txt",
      //         type: "warning",
      //         offset: window.screen.height / 2
      //     });
      // }
      console.log(data);
      if (data.state === '0x0000') {
        setTimeout(() => {
          this.id = data.data.id;
          this.name = data.data.name;
          this.imgCurrentActive = 0;
          this.prohibitClicking = true;
          this.prohibit = false;
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          this.getDocument();
          this.queslist = [];
        }, 1000);
      } else if (data.state === '0x0008') {
        this.$message.error(data.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          this.$message({
            message: data.message,
            type: 'error',
          });
        }, 1000);
      }
    },
    // 点击事件
    selectLabelDomain(index, value) {
      this.activeIndex = index;
      this.formUrl.text = value;
      this.submitForm();
    },
    // 回车发送
    handleKeydown(event) {
      if (event.keyCode === 13 && event.shiftKey == false) {
        if (!this.prohibit) {
          // console.log("123")
          this.submitForm();
        }
      }
    },
    // 发送内容
    submitForm() {
      // console.log(this.formUrl.text)
      // let test = this.formUrl.text.trim();
      // console.log(this.formUrl.text)
      if (
        this.formUrl.text.trim() == null ||
        this.formUrl.text.trim() == '' ||
        this.formUrl.text.trim() == undefined
      ) {
        this.$message({
          message: '请输入内容',
          type: 'error',
        });
        return;
      }
      // if(this.formUrl.text === ""){
      //     this.$message({
      //         message: '请输入内容',
      //         type: "error",
      //         offset: window.screen.height / 2
      //     });
      //     return;
      // }

      this.prohibit = true;
      this.loading = true;
      // 添加问题
      this.queslist.push({ text: this.formUrl.text });
      let data = {
        text: this.formUrl.text,
        docIdList: [Number(this.id)],
        isSample: this.isSample,
      };

      documentQa(data).then(({ data }) => {
        if (data.state === '0x0000') {
          this.txtList = data.data;
          this.i = 0;
          this.formUrl.text = '';
          this.loading = false;
          this.printNext();
          // this.prohibit = false
        } else if (data.state === '0x0008') {
          this.$message.error(response.message);
          this.$refs.navheader.logOn();
        } else {
          this.$message({
            message: data.message,
            type: 'error',
          });
          this.loading = false;
          this.prohibit = false;
        }
      });
    },

    // 输入内容
    printNext() {
      // 获取最后一个标签
      var pTags = document.querySelectorAll('.answerDomain');
      var lastPTag = pTags[pTags.length - 1];
      // console.log(lastPTag)
      // 追加内容
      // lastPTag.innerHTML += '';
      const str = this.txtList;
      if (this.i < str.length) {
        if (lastPTag != undefined) {
          lastPTag.innerHTML += str.charAt(this.i);
          // console.log(lastPTag.innerHTML)
          this.i++;
          this.timer = setTimeout(this.printNext, 50); // 每隔一秒输出一个元素
        }
        this.scrollToEnd();
      } else {
        this.prohibit = false;
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style scoped lang="less">
/* header */
.header-custom img {
  position: absolute;
}
.header-custom .forms {
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 73.8%;
}

.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}
/* 核心 */
.core-function {
  padding: 60px 0 63px;
}
.core-function /deep/ .common-two-column_img {
  width: 40.5%;
  margin-top: -26px;
}
.core-function /deep/ .common-two-column_img {
  margin-right: 93px;
}

.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
.core-function /deep/ .common-two-column {
  column-gap: 29px;
}
/* 能力展示 */
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
}
/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
}
.different-advantage {
  padding-top: 80px;
  padding-bottom: 147px;
}
.different-advantage /deep/ .common-two-column {
  column-gap: 109px;
}
.different-advantage /deep/ .common-two-column_img {
  width: 40.5%;
  margin-right: 9px;
  margin-top: -40px;
}

/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 148px;
  position: relative;
  .app-chat-gif {
    position: absolute;
    top: 16px;
    left: 12px;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .app-img {
    position: relative;
  }
}
.application-scene /deep/ .common-two-column {
  column-gap: 133px;
}
.application-scene /deep/ .common-two-column_img {
  width: 33.9%;
  margin-right: 25px;
  margin-top: -58px;
}

/* 能力展示 */
.similar-main-cont {
  display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
.upload-wrap {
  flex-shrink: 0;
}
.chat-wrap {
  margin-left: 24px;
  flex: 1;
  border-radius: 4px;
  opacity: 1;
  overflow: hidden;
  background: #e7e7e7;
}

.pdfName {
  background: #dfdaeb;
  font-family: PingFang SC;
  font-size: 14px;
  padding: 16px 18px;
  color: #9e9e9e;
}
.chatting {
  height: 465px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: scroll;
  padding-bottom: 20px;
}
/*滚动条样式*/
.chatting::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.chatting::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.chatting::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.problemMain {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: right;
  padding-top: 20px;
}
.problemDomain {
  max-width: 456px;
  font-size: 14px;
  font-family: PingFang-SC-Regular;
  background-color: #dfdaeb;
  color: #494b5a;
  padding: 16px;
  border-radius: 4px;
  margin-right: 10px;
  white-space: pre-wrap;
  word-break: break-all;
}
.problemDomainText {
  width: 45px;
  height: 45px;
  background: #6567e1;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang-SC-Regular;
  color: #fff;
  line-height: 45px;
  text-align: center;
  border-radius: 30px;
}
.answerMain {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-top: 20px;
}
.answerDomain {
  max-width: 456px;
  font-size: 14px;
  font-family: PingFang-SC-Regular;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  margin-left: 10px;
  min-width: 456px;
  color: #494b5a;
  max-width: 456px;
  white-space: pre-wrap;
}
.answerDomainText {
  width: 45px;
  height: 45px;
  background: #0fa37f;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang-SC-Regular;
  color: #fff;
  line-height: 45px;
  text-align: center;
  border-radius: 30px;
}
.sendContent {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 15px;
  margin-right: 15px;
  /* height: 214px; */
  background-color: #fff;
  margin-top: 15px;
  border-radius: 6px;
  padding-bottom: 25px;
  overflow: hidden;
}
.selectLabelDomain {
  font-size: 14px;
  color: #656565;
  padding-top: 20px;
}
.selectLabelDomain span {
  display: inline;
  /* line-height: 28px; */
  border: 1px solid #999999;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
  padding: 5px 10px;
  cursor: pointer;
}
.selectLabelDomain span:hover {
  color: #777aed;
  border: 1px solid #777aed;
}
.activeDomain {
  border: 1px solid #6567e1 !important;
  color: #6567e1;
}
.domainInput {
  margin-top: 20px;
}

.domainInput .el-textarea__inner {
  border: none;
  height: 130px;
  padding: 0px;
  font-family: PingFang-SC-Regular;
}
/*滚动条样式*/
.domainInput .el-textarea__inner::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.domainInput .el-textarea__inner::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.domainInput .el-textarea__inner::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.subBtn_domain {
  width: 122px;
  height: 46px;
  border-radius: 6px;
  float: right;
  display: block;
}

.logoUploadMain {
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.logoUpload {
  width: 128px;
  height: 128px;
  line-height: 128px;
}

.logoUpload /deep/ .el-upload--picture-card {
  width: 128px;
  height: 128px;
  line-height: 128px;
}
.logoExample {
  display: flex;
}

.logoExampleMain {
  width: 128px;
  height: 128px;
  line-height: 157px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.logoExampleMain img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.prohibitClicking {
  pointer-events: none;
}
.prohibit {
  pointer-events: none;
}
.loadingDomain {
  width: 150px;
  height: 15px;
  margin-left: 65px;
  position: absolute;
  bottom: 20px;
}
.loadingDomain span {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 5px;
  border-radius: 50%;
  background: #777aed;
  -webkit-animation: load 1.04s ease infinite;
}
.loadingDomain span:last-child {
  margin-right: 0px;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loadingDomain span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loadingDomain span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loadingDomain span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loadingDomain span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loadingDomain span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
.frosted_glass {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
}
.msg-tip {
  position: absolute;
  z-index: 999;
  /* left: 37%;
  top: 50%; */
  -webkit-transform: translate(300%, -1568%);
  -moz-transform: translate(300%, -1568%);
  -ms-transform: translate(300%, -1568%);
  transform: translate(300%, -1568%);
  padding: 0 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media screen and (max-width: 990px) {
  .problemDomain {
    max-width: 300px;
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    background-color: #edebf3;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
    white-space: pre-wrap;
  }
  .answerDomain {
    font-size: 14px;
    font-family: PingFang-SC-Regular;
    background-color: #edebf3;
    padding: 10px;
    border-radius: 4px;
    margin-left: 10px;
    min-width: 300px;
    max-width: 300px;
    white-space: pre-wrap;
  }
  .selectLabelDomain {
    font-size: 12px;
    color: #656565;
    padding-top: 20px;
  }
  .selectLabelDomain span {
    display: inline;
    /* line-height: 28px; */
    border: 1px solid #999999;
    border-radius: 4px 4px 4px 4px;
    margin-right: 3px;
    padding: 2px 5px;
    cursor: pointer;
  }
  .selectLabelDomain span:hover {
    color: #777aed;
    border: 1px solid #777aed;
  }
  .msg-tip {
    position: absolute;
    z-index: 999;
    /* left: 37%;
    top: 50%; */
    -webkit-transform: translate(100%, -1568%);
    -moz-transform: translate(100%, -1568%);
    -ms-transform: translate(100%, -1568%);
    transform: translate(100%, -1568%);
    padding: 0 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 36px;
    line-height: 36px;
    color: #666;
    background: #fff;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    position: absolute;
    width: 1440px;
    height: 820px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
